<template>
  <!-- ROUTE -->
  <div id="router" v-if="!loading">
    <!-- NAVBAR-->
    <div id="navbar" class=" h-20 flex gap-4 py-4 md:py-3 items-center justify-between px-4 lg:px-10"
      v-if="$route.path != '/error'">
      <p class="font-semibold">PUNTO DE RETIRO</p>
      <ConfigMenu></ConfigMenu>
    </div>

    <!-- ROUTER VIEW -->
    <router-view class="text-base" :class="$route.path != '/error' ? 'h-[calc(100vh_-_5rem)]' : 'h-screen'" />
  </div>

  <!-- LOADING -->
  <div id="loading" class="h-screen flex flex-col items-center justify-center gap-4" v-else>
    <div role="status" class="text-center">
      <div
        class="inline-block h-20 w-20 animate-spin rounded-full border-4 border-solid border-current border-e-neutral-900 align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
      </div>
    </div>
    <p>{{ loadingMessage }}</p>
  </div>

  <!-- PWA UPDATES-->
  <div
    class="update py-4 fixed top-0 left-0 bg-primary-900 w-full h-full text-white bg-black flex flex-col gap-4 p-8 items-center justify-center z-50"
    v-if="updateExists">
    <div class="text text-center space-y-4">
      <div role="status" class="text-center">
        <div
          class="inline-block h-20 w-20 animate-spin rounded-full border-4 border-solid border-current border-e-neutral-900 align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status">
        </div>
      </div>
      <p>Actualizando...</p>
      <p class="font-bold">Aguarde un momento, por favor</p>
    </div>
  </div>
</template>

<script lang="ts">
//update
import update from "./update"

// utilities
import utilities from "./utilities"

//Components
import ConfigMenu from "@/components/ConfigMenu.vue";

//This component
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppView',
  mixins: [utilities, update],
  components: {
    ConfigMenu
  },
  created() {
    this.init()
  }
})
</script>

<style>
#app {
  font-family: Poppins, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
