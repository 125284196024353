import { createStore } from 'vuex'
import vuexPersistance from 'vuex-persist';

export default createStore({
  state: {
    version: 0,
    printers: [] as any,
    config: {
      cinemaId: 0,
      printer: "",
      mode: '',
      enableSales: false
    }
  },
  getters: {
  },
  mutations: {
    setVersion(state, data){
      state.version = data
    },
    setCinemaId(state, data){
      state.config.cinemaId = data
    },
    setPrintersList(state, data){
      state.printers = data
    },
    setPrinter(state, data){
      state.config.printer = data
    },
    setMode(state, data) {
      state.config.mode = data
    },
    setConfig(state, data){
      state.config = data
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    new vuexPersistance({
      storage: window.localStorage
    }).plugin
  ]
})
