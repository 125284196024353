<template>
  <div id="start">
      <p>START VIEW</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'StartView',
});
</script>
