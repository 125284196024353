<template>
    <div id="toastContainer" class="w-full md:w-1/3 p-6 fixed left-1/2 -translate-x-1/2 transition-all duration-500 ease-in-out z-50"
        :class="show ? 'bottom-0' : '-bottom-[100%]'">
        <div id="toast-warning" class="rounded-lg overflow-hidden bg-zinc-900 border border-zinc-800" role="alert">
            <!-- progress bar -->
            <div class="w-full rounded-full h-1.5 bg-white bg-opacity-5">
                <div
                id="progressBar"
                :style="{ width: show ? '100%' : '0' }"
                :class="['h-full', 'rounded-full', 'ease-linear', 'transition-[width]', style.bgColor, show ? 'duration-[4s]' : 'duration-0']"
                ></div>

            </div>
            <!-- message -->
            <div id="msgContainer" class="flex items-center p-4" :class="style.textColor">
                <div class="inline-flex items-center justify-center flex-shrink-0 p-3 rounded-lg bg-white bg-opacity-5">
                    <!--Success-->
                    <svg v-if=" type == 'success' " class="w-6 h-6" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                        <path d="M22 4 12 14.01l-3-3"></path>
                    </svg>
                    <!--Info-->
                    <svg v-else-if=" type == 'info' " class="w-6 h-6" fill="none" stroke="currentColor" stroke-linecap="round"
                        stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2a10 10 0 1 0 0 20 10 10 0 1 0 0-20z"></path>
                        <path d="M12 16v-4"></path>
                        <path d="M12 8h.01"></path>
                    </svg>
                    <!--Warning-->
                    <svg v-else-if=" type == 'warning' " class="w-6 h-6" fill="none" stroke="currentColor"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.29 3.86 1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                        </path>
                        <path d="M12 9v4"></path>
                        <path d="M12 17h.01"></path>
                    </svg>
                    <!--Error-->
                    <svg v-else-if=" type == 'error' " class="w-6 h-6" fill="none" stroke="currentColor"
                        stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.86 2h8.28L22 7.86v8.28L16.14 22H7.86L2 16.14V7.86L7.86 2z"></path>
                        <path d="m15 9-6 6"></path>
                        <path d="m9 9 6 6"></path>
                    </svg>
                </div>
                <div class="ml-3 text-sm font-normal">
                    <p v-html="msg"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
    name: 'ToastMsg',
    data(){
        return {
            style: {} as any[any],
            statusColors: [
                {
                    type: 'success',
                    textColor: 'text-green-300',
                    bgColor: 'bg-green-300'
                },
                {
                    type: 'info',
                    textColor: 'text-sky-300',
                    bgColor: 'bg-sky-300'
                },
                {
                    type: 'warning',
                    textColor: 'text-amber-300',
                    bgColor: 'bg-amber-300'
                },
                {
                    type: 'error',
                    textColor: 'text-red-500',
                    bgColor: 'bg-red-500'
                }
            ]
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        msg: String,
        type: {
            type: String,
            default: 'warning'
        },
    },
    watch: {
        show(newValue) {
            if (newValue) {
                this.setStyle();
                        setTimeout(() => {
                    this.$emit('update', false);
                }, 4000);
            }
        },
    },
    methods: {
        setStyle(){
            this.style = this.statusColors.find((elem: any) => elem.type === this.type) || {};
        }
    }
})
</script>