import axios from 'axios'
import { defineComponent, ref } from "vue";
import { version } from "../package.json"

export const loading = ref(false)
export const loadingMessage =  ref('')

export default defineComponent({
    mounted(){
        //set app version
        this.$store.commit('setVersion', version)
    },
    data() {
        return {
            apiUrl: (process.env.NODE_ENV === 'production')
                ? 'https://apiv3.gaf.adro.studio'
                : 'http://192.168.1.100',
            printerApiUrl: (process.env.NODE_ENV === 'production')
                ? 'http://localhost:3000'
                : 'http://192.168.1.100:3000',
            loading,
            loadingMessage,
            toastMsg: '',
            toastType: '',
            toastDisplay: false
        }
    },
    methods: {
        //INIT SYSTEM
        async init() {
            //set loading
            this.loading = true

            let cinemaId = undefined

            console.log(this.$store.state)

            //get cinema id
            if(this.$store.state.config.cinemaId != 0){
                cinemaId = this.$store.state.config.cinemaId
            }
            else{
                const urlParams = new URLSearchParams(window.location.search);
                
                if(urlParams.get('cinemaId')){
                    cinemaId = urlParams.get('cinemaId');
                    this.$store.commit('setCinemaId', cinemaId)
                } 
            }
            
            if(!cinemaId){
                //push to error page
                this.$router.push('/error?msg=noCinemaDefined')

                //unset loading
                this.loading = false

                //exit
                return
            }
            
            //get printers
            this.loadingMessage = 'Obteniendo Impresoras'
            await this.getPrinters() as any

            if(this.$store.state.printers){
                if (!this.$store.state.config.enableSales) {
                    this.$router.push('/retiro')
                }
            }
            
            //unset loading
            this.loading = false
        },

        //GET PRINTERS
        async getPrinters() {
            try {
                const response = await axios.get(this.printerApiUrl + '/printers');
                if (response.status === 200) {
                    this.$store.commit('setPrintersList', response.data.printers);
                }
            } catch (e) {
                this.$router.push('/error?msg=NoPrinterService')
                return
            }
        },

        //GET FROM API
        async getFromApi(request: string) {
            console.log('Llamado')
            try {
                const response = await axios.get(this.apiUrl + request)
                console.log('Funciona')
                console.log(response)

                if (response.status === 200) {
                    this.loading = false
                    return response
                }
            } catch (error: any) {

                if (error.response.status === 422) {
                    this.loading = false
                    return error.response
                }
                else {
                    console.log('Es un error')
                    this.loading = false
                    this.$store.state.$router.push('/error?msg=NoPrinterService')
                    return
                }

            }
        },

        //PRINT TICKETS
        async printTickets(tickets: any[any]) {

            //set raw tickets array
            const rawTickets = [] as any[any]

            //parse tickets
            tickets.forEach((ticket: any, index: number) => {

                //trim movie title
                ticket.pelicula = this.formatText(ticket.pelicula, 18)
                ticket.cine = this.formatText(ticket.cine, 18)
                ticket.sala = this.formatText(ticket.sala, 18)

                //CHECK POSITION
                ticket.posicion = (ticket.ubicaciones) ? ticket.ubicaciones.split("|")[index] : null

                const ReceiptPrinterEncoder = (window as any).ReceiptPrinterEncoder
                const encoder = new ReceiptPrinterEncoder({
                    width: 48,
                    feedBeforeCut: 4
                });

                // Establecer el interlineado
                //const lineSpacing = 30;

                //.raw([0x1B, 0x33, lineSpacing])

                const data = [
                    [this.setTableLine(encoder, 0, 1, ticket.cine), ticket.cine],
                    [this.setTableLine(encoder, 0, 1, 'Sala '+ticket.sala), 'Sala '+ticket.sala],
                    [this.setTableLine(encoder, 0, 1, ticket.fechaVenta + ' - ' + ticket.horaVenta), ticket.fechaVenta + ' - ' + ticket.horaVenta],
                    [this.setTableLine(encoder, 24, 2, ticket.pelicula), ticket.pelicula],
                    [this.setTableLine(encoder, 24, 2, 'FECHA: ' + ticket.fechaFuncion), 'FECHA: ' + ticket.fechaFuncion],
                    [this.setTableLine(encoder, 24, 2, 'HORA: ' + ticket.horaFuncion), 'HORA: ' + ticket.horaFuncion]
                ]

                if(ticket.posicion !== null) {
                    data.push(
                        [this.setTableLine(encoder, 24, 2, 'POS: ' + ticket.posicion), 'POS: ' + ticket.posicion]
                    )
                }

                data.push(
                    [this.setTableLine(encoder, 24, 1, ticket.nroEntrada), ticket.nroEntrada],
                    [this.setTableLine(encoder, 0, 2, 'PRECIO: $' + ticket.precio), 'PRECIO: $' + ticket.precio],
                    [this.setTableLine(encoder, 24, 1, 'SALA: ' + ticket.codigoSala), 'SALA: ' + ticket.codigoSala],
                    [this.setTableLine(encoder, 0, 1, 'FUNCION: ' + ticket.funcion), 'FUNCION: ' + ticket.funcion],
                    [this.setTableLine(encoder, 0, 1, 'CUIT: ' + ticket.cuit), 'CUIT: ' + ticket.cuit],
                    [this.setTableLine(encoder, 0, 1, 'TALON PARA EL ESPECTADOR'), 'TALON PARA URNA']
                )

                const encodedTicket = encoder
                    .initialize()
                    .font('B')
                    .table(
                        [
                            { width: 42, marginRight: 2, align: 'left' },
                            { width: 20, align: 'left' }
                        ],
                        data
                    )
                    .cut()
                    .encode()

                rawTickets.push(Array.from(encodedTicket))
            });

            //set post data
            const data = {
                printer: this.$store.state.config.printer,
                tickets: rawTickets
            }

            let response = {} as any

            if(this.$store.state.config.mode == 'ESCPOS') {
                //post to print service
                response = await axios.post(this.printerApiUrl + '/print', data)
            }
            else if(this.$store.state.config.mode == 'PDF') {
                //post to print service
                response = await axios.post(this.printerApiUrl + '/printpdf', data)
            }
            

            //handle response
            if (response.status == 200) {
                console.log('Impresión completa')
            }
            else {
                console.log('Falló la impresión')
            }
        },

        //SET TABLE LINE
        setTableLine(encoder: any, prevLineSpace: number, fontSize: number, text: string) {
            const result = (encoder: any) => encoder.raw([0x1b, 0x4a, '0x' + prevLineSpace.toString(16)]).width(fontSize).height(fontSize).text(text)
            return result
        },

        //FORMAT TEXT
        formatText(text: string, limit: number) {
            // Mapeo de caracteres acentuados a sus equivalentes sin acento, preservando 'ñ'
            const accentsMap: { [key: string]: string } = {
                'á': 'a',
                'é': 'e',
                'í': 'i',
                'ó': 'o',
                'ú': 'u',
                'Á': 'A',
                'É': 'E',
                'Í': 'I',
                'Ó': 'O',
                'Ú': 'U',
                'ü': 'u',
                'Ü': 'U',
                // Se puede agregar más si es necesario
            };

            // Reemplazar los caracteres acentuados utilizando el mapeo
            const normalizedText = text.split('').map(char => {
                return accentsMap[char] || char; // Reemplaza el carácter si está en el mapa, si no, lo deja igual
            }).join('');

            // Limit the text to the specified length
            return normalizedText.length > limit ? normalizedText.slice(0, limit) : normalizedText;
        },

        //SHOW TOAST
        showToast(type: string, message: string) {
            console.log('toast')
            this.toastType = type
            this.toastMsg = message
            this.toastDisplay = true
        }
    }
})