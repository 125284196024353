<template>
  <div id="configMenu">
    <!-- CONFIG ICON -->
    <Icon iconImg="/assets/icons/settings.svg" class="w-5 bg-white" @click="openMenu()"></Icon>

    <!-- CONFIG SIDEBAR -->
    <div class="configMenu w-[300px] px-4 fixed top-0 h-full bg-neutral-900 flex flex-col ease-linear duration-100 z-20"
      :style="showMenu ? 'right:0' : 'right:-300px'">
      <!-- header -->
      <div class="header w-full py-4 flex items-center justify-between">
        <p class="text-xl font-bold">Configuración</p>
        <div class="close-icon w-6 h-6 cursor-pointer" @click="showMenu = false">
          <img src="/assets/icons/close.svg" alt="close" />
        </div>
      </div>
      <!-- body -->
      <div class="body overflow-auto flex-1">
        <div class="section-divider py-4 mb-4 border-b border-gray-600">
          <p>Impresión de tickets</p>
        </div>
        <label for="printer">
          <p class="text-sm mb-2">Impresora:</p>
        </label>

        <!--Select printer-->
        <select name="printer" id="printer" class="w-full text-black p-2 rounded-md outline-none mb-4"
          @change="setPrinter($event)">
          <option hidden>Seleccione Una</option>
          <option v-for="(printer, index) in $store.state.printers" :key="index" :value="printer"
            :selected="printer == $store.state.config.printer">
            {{ printer }}
          </option>
        </select>

        <label for="mode">
          <p class="text-sm mb-2">Modo:</p>
        </label>

        <!--Select mode-->
        <select name="mode" id="mode" class="w-full text-black p-2 rounded-md outline-none"
          @change="setMode($event)">
          <option hidden>Seleccione uno</option>
          <option v-for="(mode, index) in printModes" :key="index" :value="mode"
            :selected="mode == $store.state.config.mode">
            {{ mode }}
          </option>
        </select>

        <!-- Print Test -->
        <input class="w-full py-2 text-black rounded-md mt-4 bg-neutral-200" type="button"
          value="Imprimir Ticket Prueba" @click="printTest()" />
      </div>

      <!-- QUIT APP -->
      <!--div class="
          quit
          py-6
          border-t
          border-b
          border-gray-600
          flex
          justify-between
          cursor-pointer
        " @click="closeApp()">
        <p>Cerrar App</p>
        <div class="icon w-5 h-5 bg-white"
          :style="'-webkit-mask-image: url(/assets/icons/power-outline.svg); -webkit-mask-repeat: no-repeat; -webkit-mask-position: center;'">
        </div>
      </div-->

      <!-- app version -->
      <div class="appVersion text-center py-4">
        <p>GAF Kiosk v{{ $store.state.version }}</p>
      </div>

      <!-- TOAST -->
      <Toast :type="toastType" :msg="toastMsg" :show="toastDisplay" :position="'right'" @update="toastDisplay = $event">
      </Toast>
    </div>
  </div>
</template>

<script lang="ts">
//Utilities
import utilities from "@/utilities";

//custom components
import Icon from "@/components/Icon.vue";
import Toast from "@/components/Toast.vue";

import { defineComponent } from "vue";

export default defineComponent({
  name: "ConfigMenu",
  mixins: [utilities],
  data() {
    return {
      appVersion: "",
      showMenu: false,
      printers: [] as any[any],
      kioskMenuCounter: 0,
      printModes: [
        'ESCPOS', 'PDF'
      ]
    };
  },
  components: {
    Icon,
    Toast
  },
  /*mounted() {
    //get app version
    electron.send("app_version");
    electron.on("app_version", (arg: any[any]) => {
      this.appVersion = arg[0].version
    })

    //get printers
    electron.send("getPrinters");
    electron.on("receivePrinters", (printers: any) => {
      this.printers = printers[0];
      
      //set stored printer
      this.printers.forEach((printer:any)=>{
        if(printer.selected){
          this.$store.commit("setPrinter", printer.name);
        }
      })
    });
  },*/
  methods: {
    openMenu() {
      if (this.kioskMenuCounter < 4) {
        this.kioskMenuCounter++
      } else {
        // show config menu
        this.showMenu = true;

        // reset config menu count
        this.kioskMenuCounter = 0
      }
    },

    //SET PRINTER
    setPrinter(event: Event) {
      const selectedPrinter = (event.target as HTMLSelectElement).value
      this.$store.commit("setPrinter", selectedPrinter);
      this.showToast('success', 'Se cambió la impresora a: ' + this.$store.state.config.printer)
    },

    //SET MODE
    setMode(event: Event) {
      const selectedMode = (event.target as HTMLSelectElement).value
      this.$store.commit("setMode", selectedMode);
      this.showToast('success', 'Se cambió la impresión a modo ' + this.$store.state.config.printer)
    },

    //PRINT TEST TICKET
    printTest() {
      //const toast = this.$refs.toast as any;
      if (this.$store.state.config.printer !== "" && this.$store.state.config.mode !== '') {
        const ticket = [{
          "cine": "CINE PRUEBA",
          "sala": "Sala Prueba",
          "fechaVenta": "00/00/000",
          "horaVenta": "00:00",
          "fechaFuncion": "00/00/0000",
          "horaFuncion": "00:00",
          "precio": 0,
          "ubicaciones": null,
          "nroEntrada": "000",
          "pelicula": "PELÍCULA PRUEBA",
          "codigoSala": "000000",
          "funcion": 0,
          "cuit": "00-000000-00"
        }]
        
        this.printTickets(ticket);

        this.toastType = 'success'
        this.toastMsg = 'Se envió un ticket de prueba a ' + this.$store.state.config.printer
        this.toastDisplay = true

      } else {
        this.toastType = 'warning'
        this.toastMsg = 'Primero debe seleccionar una impresora y un modo'
        this.toastDisplay = true
      }
    },
    closeApp() {
      console.log('Closing App...')
    }
  },
});
</script>
