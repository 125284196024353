import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import StartView from '../views/StartView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'inicio',
    component: StartView
  },
  {
    path: '/vincular',
    name: 'link',
    component: () => import('../views/LinkView.vue')
  },
  {
    path: '/retiro',
    name: 'pick-up',
    component: () => import('../views/TicketsPickUp.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/ErrorView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
